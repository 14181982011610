export const SALES_TYPE = {
  PAYMENT: 'payment',
  FREE: 'free'
};

export const PRICE_ITEM_STATUS = {
  REQUEST: 'REQUEST',
  START: 'START',
  REJECT: 'REJECT',
  NONE: 'NONE',
  PASS: 'PASS'
} as const;

export const PRICE_ITEM_REQUEST_STATUS = {
  REQUEST: 'REQUEST',
  START: 'START',
  REJECT: 'REJECT',
  NONE: 'NONE',
  PASS: 'PASS'
} as const;

export const MAX_VALUE_PRICE_INPUT = 999999.99;
export const MAX_VALUE_PRICE_INPUT_KRW = 9999999990;

export const MIN_VALUE_PRICE_INPUT = 0.99;
export const MIN_VALUE_PRICE_INPUT_KRW = 10;
