<template>
  <div class="flex items-center" :class="paymentPriceValue ? 'gap-4' : 'gap-8'">
    <div
      v-if="
        paymentPriceValue !== null && paymentPriceValue !== undefined && !isNaN(paymentPriceValue)
      "
      class="flex items-center gap-8 ml-auto px-4"
    >
      <span
        v-if="originalPriceValue"
        class="text-xs leading-md text-on-surface-elevation-3 line-through [&+.text-on-surface-elevation-2]:!text-brand-primary"
      >
        {{ formatPrice(originalPriceValue, currencyCode) }}
      </span>
      <span
        class="text-sm font-bold leading-md"
        :class="isStatusMode ? 'text-on-surface-elevation-3' : 'text-on-surface-elevation-2'"
      >
        {{
          currencyCode !== CurrencyCode.Krw
            ? formatPrice(paymentPriceValue || 0, currencyCode)
            : formatPrice(Math.floor(paymentPriceValue / 10) * 10, currencyCode)
        }}
      </span>
    </div>
    <template v-else>
      <input-text
        v-model="tempValue"
        :maxLength="'14'"
        :disabled="isDisabled"
        placeholder="0.00"
        :countable="false"
        :clearable="false"
        :currencyOptions="{ currency: 'USD' }"
        :caretPosition="Position.RIGHT"
        containerClass="flex-1 [&>.stds-input-wrapper>.stds-input]:tracking-[0] [&>.stds-input-wrapper>.stds-input]:text-right [&>.stds-input-clear-button]:hidden"
        @blur="onBlurPrice"
      />

      <input-text :maxLength="'14'" :name="name" :rules="defaultRules" containerClass="hidden" />
    </template>
    <span
      class="min-w-[3rem] text-sm font-medium leading-md text-on-surface-elevation-4 text-right"
    >
      {{ currencyCode }}
    </span>
  </div>
  <st-error-message v-if="showError" :errorMessage="error" />
</template>
<script setup lang="ts">
import { useFieldError, useFieldValue, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/common/st-error-message.vue';
import InputText from '@/components/validation/input-text.vue';
import {
  MAX_VALUE_PRICE_INPUT,
  MAX_VALUE_PRICE_INPUT_KRW,
  MIN_VALUE_PRICE_INPUT,
  MIN_VALUE_PRICE_INPUT_KRW
} from '@/constants/selling-price.const';
import { Position } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { RuleNames } from '@/enums/validation.enum';
import { formatPrice } from '@/utils/currency.util';
import { formatMoney } from '@/utils/string.util';
import { generateErrorMsg } from '@/utils/validation.util';

const REGEX = /^[0-9.]+$/;
const REGEX_WITH_COMMA = /^[0-9,.]+$/;

const props = defineProps<{
  currencyCode: string;
  originalPriceValue?: number;
  paymentPriceValue?: number;
  isStatusMode?: boolean;
  isDisabled?: boolean;
  name?: string;
  defaultPrice?: number;
  isViewMode?: boolean;
}>();

const { defaultPrice } = toRefs(props);

const inputValue = useFieldValue<number>(props.name);
const setInputValue = useSetFieldValue(props.name);
const error = useFieldError(props.name);
const setInputError = useSetFieldError(props.name);

const tempValue = ref('');

const showError = computed(() => {
  if (props.currencyCode === CurrencyCode.Krw) {
    if (error.value) {
      if (
        JSON.parse(error.value).rule === RuleNames.MULTIPLY_BY_TEN &&
        JSON.parse(error.value).message !== ''
      ) {
        return true;
      }
    }
  }

  return false;
});

const MAX_VALUE =
  props.currencyCode === CurrencyCode.Krw ? MAX_VALUE_PRICE_INPUT_KRW : MAX_VALUE_PRICE_INPUT;
const MIN_VALUE =
  props.currencyCode === CurrencyCode.Krw ? MIN_VALUE_PRICE_INPUT_KRW : MIN_VALUE_PRICE_INPUT;
const MAX_LENGTH = props.currencyCode === CurrencyCode.Krw ? 10 : 9;
const DECIMAL_COUNT = props.currencyCode === CurrencyCode.Krw ? 0 : 2;

const defaultRules = computed(() => {
  const rules = {
    required: {
      value: true,
      showError: false,
      message: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_required_val_msg2'
    },
    gte: {
      value: MIN_VALUE,
      message:
        'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg3',
      showError: false
    },
    lte: {
      value: MAX_VALUE,
      showError: false
    },
    regex: {
      pattern: REGEX,
      message:
        'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
      showError: false
    },
    max_length: {
      value: MAX_LENGTH,
      message: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_price_invalid_val_msg5',
      showError: false
    }
  };

  if (props.currencyCode !== CurrencyCode.Krw) {
    return rules;
  }

  return {
    ...rules,
    multiply_by_ten: {
      value: true,
      message: 'studio.prj_prod.this_prod.prod_mgmt_price_reg_payment_price_invalid_val_msg2',
      showError: false
    }
  };
});

const init = () => {
  if (defaultPrice.value) {
    setInputValue(defaultPrice.value);
  }
};

const onChangePrice = (value: string) => {
  const match = value.match(REGEX_WITH_COMMA) ? value.match(REGEX_WITH_COMMA)!.length > 0 : false;
  if (match) {
    const price = value.replace(/,/g, '');
    const newPrice = formatMoney(Number(price), DECIMAL_COUNT);
    tempValue.value = newPrice;
    setInputValue(newPrice.replace(/,/g, ''));
    return;
  }
  if (value === '') {
    setInputValue('');
    return;
  }
  const errorMsg = generateErrorMsg(
    'studio.prj_prod.this_prod.prod_mgmt_price_reg_standard_price_manual_input_invalid_val_msg2',
    RuleNames.REGEX
  );
  setInputError(errorMsg);
};

const onBlurPrice = () => {
  onChangePrice(tempValue.value);
};

init();

watch(
  defaultPrice,
  () => {
    setInputValue(defaultPrice.value);
    if (defaultPrice.value === 0) {
      tempValue.value = '';
      return;
    }

    tempValue.value = formatMoney(defaultPrice.value || 0, DECIMAL_COUNT);
  },
  { immediate: true }
);

watch(
  () => error.value,
  () => {
    if (error.value && JSON.parse(error.value).rule === RuleNames.LTE) {
      setInputValue(MAX_VALUE);
      onChangePrice(MAX_VALUE.toString());
    }

    if (error.value && JSON.parse(error.value).rule === RuleNames.GTE) {
      setInputValue(undefined);
      tempValue.value = '';
    }
  }
);

watch(
  () => inputValue.value,
  () => {
    if (inputValue.value === 0) {
      tempValue.value = '';
      return;
    }

    setInputValue(inputValue.value);
    tempValue.value = formatMoney(inputValue.value || 0, DECIMAL_COUNT);
  }
);
</script>
